<template>
  <div class="o-content-box">
    <div class="u-flex u-width-1280 u-flex-direction-column@mobile u-ml--a u-mr--a">
      <div class="u-flex-1">
        <h1 class="u-font-size-h2 u-font-alternative">
          {{ model.heading }}
        </h1>
        <p class="u-font-size-xlarge u-mb--m">
          {{ model.description }}
        </p>
        <div
          v-for="(teacherClass) in teacherModel.classes"
          :key="teacherClass.id"
          class="c-foodmovers__table u-flex u-flex-direction-column@mobile u-mb--s"
        >
          <div class="u-flex u-flex-direction-column">
            <span class="c-label--m u-text-dark-gray">{{ model.classSection.classNameLabel }}</span>
            <span class="u-font-bold u-font-size-h4 u-m--0">{{ teacherClass.name }}</span>
          </div>
          <div
            class="u-flex"
            :class="{
              'u-mt--m': $mq != 'desktop',
              'u-ml--a': $mq == 'desktop'
            }"
          >
            <div class="u-flex u-flex-direction-column u-mr--m">
              <span class="c-label--m u-text-dark-gray">{{ model.classSection.week1PointsLabel }}</span>
              <span class="u-font-size-h4 u-m--0">{{ teacherClass.week1Points }}</span>
            </div>
            <div class="u-flex u-flex-direction-column u-mr--m">
              <span class="c-label--m u-text-dark-gray">{{ model.classSection.week2PointsLabel }}</span>
              <span class="u-font-size-h4 u-m--0">{{ teacherClass.week2Points }}</span>
            </div>
            <div class="u-flex u-flex-direction-column u-mr--m">
              <span class="c-label--m u-text-dark-gray">{{ model.classSection.week2PointsLabel }}</span>
              <span class="u-font-size-h4 u-m--0">{{ teacherClass.week3Points }}</span>
            </div>
          </div>
          <div
            class="u-flex u-flex-align-center"
            :class="{
              'u-mt--m u-mb--s': $mq != 'desktop'
            }"
          >
            <div class="u-flex u-flex-direction-column">
              <span class="c-label--m u-text-dark-gray">{{ model.classSection.totalPointsLabel }}</span>
              <span class="u-font-size-h4 u-m--0 u-font-bold">{{ teacherClass.totalPoints }}</span>
            </div>
            <div
              class="u-flex u-flex-align-center"
              :class="{
                'u-ml--l': $mq == 'desktop',
                'u-ml--a': $mq != 'desktop'
              }"
            >
              <button
                class="c-button c-button--dark c-button--small"
                @click="editClass(teacherClass)"
              >
                {{ model.classSection.editClassButtonText }}
              </button>
              <button
                v-if="!isDisabled"
                class="c-button--icon-large"
                :class="{
                  'u-ml--m': $mq == 'desktop',
                  'u-ml--s': $mq != 'desktop'
                }"
                @click="showDeleteConfirmation(teacherClass)"
              >
                <Icon
                  :icon="iconTrash"
                  :size="24"
                  fill="black"
                />
              </button>
            </div>
          </div>
        </div>
        <button
          class="c-foodmovers__add-class u-width-100 u-flex u-flex-justify-center u-flex-align-center u-mb--l"
          :class="{'disabled': isDisabled}"
          @click="addClass"
        >
          <Icon
            v-if="!isDisabled"
            :icon="iconAdd"
            :size="16"
            fill="black"
          />
          <span class="c-label--m u-ml--xs">
            <template v-if="isDisabled">
              {{ amountOfStudentsExceededMessageText }}
            </template>
            <template v-else>
              {{ model.addClassText }}
            </template>
          </span>
        </button>
        <h3 class="u-font-alternative u-font-size-h4">
          {{ model.reminderHeading }}
        </h3>
        <div
          class="u-font-size-xlarge"
          v-html="model.reminderDescription"
        />
      </div>
      <div
        :class="{
          'u-ml--l': $mq == 'desktop'
        }"
      >
        <div class="c-label--m u-text-dark-gray u-mb--xs">
          {{ greetings }}
        </div>
        <div class="u-font-size-large">
          <div>{{ currentUserEmail }}</div>
          <div>{{ teacherModel.phoneNumber }}</div>
          <div>{{ teacherModel.school.name }}</div>
          <div>{{ teacherModel.school.address }}</div>
          <div>{{ teacherModel.school.postalCode }} {{ teacherModel.school.city }}</div>
        </div>
        <div class="u-mt--s u-mb--m">
          <button
            class="c-button c-button--small c-button--light"
            @click.prevent="editForm"
          >
            {{ model.sidebarEditProfileLinkText }}
          </button>
        </div>
        <a
          :href="model.sidebarLogoutUrl"
        >
          {{ model.sidebarLogoutText }}</a>
      </div>
    </div>
    <Modal
      v-model="showModal"
      :is-title-centered="false"
      :width="540"
      :title="model.classPopupViewModel.heading"
    >
      <ClassModal
        :model="model.classPopupViewModel"
        :cant-edit="isDisabled"
        :teacher-class="activeClass"
        @class-saved="classSaved"
        @class-deleted="deleteClass"
        @class-cancel="closeModal"
        @amount-updated="amountUpdated"
      />
    </Modal>
    <Modal
      v-model="deleteConfirmationShown"
      :is-title-centered="false"
      :width="540"
      :title="model.deleteClassPopup.heading"
    >
      <DeleteClassModal
        :model="model.deleteClassPopup"
        :delete-id="classToDelete.id"
        @class-deleted="deleteClass"
        @cancel-deleting="hideDeleteConfirmation"
      />
    </Modal>
  </div>
</template>

<script>
import iconAdd from '@ds/svg/icons/bold/add-bold.svg'
import iconTrash from '@ds/svg/icons/bold/bin-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import ClassModal from '@/CVI/FoodMovers/components/ClassPopup.vue'
import DeleteClassModal from '@/CVI/FoodMovers/components/DeleteClassPopup.vue'

export default {
  components: {
    Icon,
    DeleteClassModal,
    ClassModal,
    Modal
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    teacher: {
      type: Object,
      required: true
    },
    currentUserEmail: {
      type: String,
      required: true
    },
    cantEdit: {
      type: Boolean,
      default: false
    },
    amountOfStudentsExceededMessageText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      teacherModel: this.teacher,
      iconAdd,
      iconTrash,
      deleteConfirmationShown: false,
      showModal: false,
      activeClass: {},
      classToDelete: {},
      isDisabled: this.cantEdit
    }
  },
  computed: {
    greetings() {
      const { firstName } = this.teacher
      return this.model.sidebarHeading?.replace('{0}', firstName)
    }
  },
  watch: {
    editForm() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    activeClass(value) {
      if (value) {
        this.showModal = true
      } else {
        this.showModal = false
      }
    }
  },
  methods: {
    editForm() {
      this.$emit('edit-form')
    },
    editClass(teacherClass) {
      if (teacherClass == this.activeClass) {
        this.activeClass = null
      }
      this.$nextTick(() => {
        this.activeClass = teacherClass
      })
    },
    addClass() {
      this.activeClass = {}
    },
    closeModal() {
      this.activeClass = null
    },
    classSaved(teacherClass) {
      const index = this.teacherModel.classes.findIndex(({ id }) => teacherClass.id == id)
      if (index > -1) {
        this.teacherModel.classes[index] = teacherClass
      } else {
        this.teacherModel.classes.push(teacherClass)
      }
      this.closeModal()
    },
    deleteClass(deletedId) {
      this.teacherModel.classes = this.teacherModel.classes.filter(({ id }) => id !== deletedId)
      this.closeModal()
      this.hideDeleteConfirmation()
    },
    showDeleteConfirmation(teacherClass) {
      this.classToDelete = teacherClass
      this.deleteConfirmationShown = true
    },
    hideDeleteConfirmation() {
      this.deleteConfirmationShown = false
    },
    amountUpdated(state) {
      this.isDisabled = state
    }
  }
}
</script>
