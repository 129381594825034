<template>
  <div>
    <p class="u-m--0 u-mb--m" v-html="description" />
    <div class="u-flex">
      <button
        class="c-button c-button--dark u-mr--s"
        :class="{
          'is-loading': deletingProfile
        }"
        @click="deleteProfile"
      >
        <span class="c-button__text">
          {{ model.deleteButtonText }}
        </span>
        <Loader
          v-if="deletingProfile"
          size="small"
          class="c-button__loader"
          color="white"
        />
      </button>
      <button
        class="c-button c-button--light"
        @click="cancel"
      >
        {{ model.cancelButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
import api from '@/CVI/FoodMovers/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    logoutUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      deletingProfile: false
    }
  },
  computed: {
    description() {
      const { helpLink, description } = this.model
      if (helpLink.url && helpLink.text) {
        const linkHtml = `<a href="${helpLink.url}">${helpLink.text}</a>`
        return description.replace('{0}', linkHtml)
      }
      return description
    }
  },
  methods: {
    async deleteProfile() {
      const { logoutUrl } = this
      this.deletingProfile = true
      try {
        await api.foodmovers.deleteProfile()
        window.location.href = logoutUrl
      } catch (e) {
        this.cancel()
      }
      this.deletingProfile = false
    },
    cancel() {
      this.$emit('cancel-deleting')
    }
  }
}
</script>
