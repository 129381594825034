<template>
  <div class="u-flex">
    <button
      class="c-button c-button--dark u-mr--s"
      :class="{
        'is-loading': deletingClass
      }"
      @click="deleteClass"
    >
      <span class="c-button__text">
        {{ model.deleteButtonText }}
      </span>
      <Loader
        v-if="deletingClass"
        size="small"
        class="c-button__loader"
        color="white"
      />
    </button>
    <button
      class="c-button c-button--light"
      @click="cancel"
    >
      {{ model.cancelButtonText }}
    </button>
  </div>
</template>

<script>

import api from '@/CVI/FoodMovers/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    deleteId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      deletingClass: false
    }
  },
  methods: {
    async deleteClass() {
      this.deletingClass = true
      const { deleteId } = this
      const { data } = await api.foodmovers.deleteClass(deleteId)
      this.deletingClass = false
      this.$emit('class-deleted', deleteId)
      this.$emit('amount-updated', data.cantEdit)
    },
    cancel() {
      this.$emit('cancel-deleting')
    }
  }
}
</script>
