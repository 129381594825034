<template>
  <div class="o-content-box">
    <div class="u-width-720 u-ml--a u-mr--a">
      <h1 class="u-font-size-h2 u-font-alternative">
        {{ model.heading }}
      </h1>
      <p class="u-m--0 u-mb--m u-font-size-xlarge">
        {{ model.description }}
      </p>
      <h3 class="u-mb--m">
        {{ model.teacherSection.sectionHeading }}
      </h3>
      <div class="u-flex u-flex-direction-column@mobile">
        <form
          class="c-foodmovers__form"
          novalidate
          @submit.stop="submitForm"
        >
          <div class="c-foodmovers__form-section">
            <div class="u-flex u-flex-direction-column u-mb--s">
              <label class="c-label--m u-mb--xs">{{ model.teacherSection.firstNameLabel }}</label>
              <input
                v-model="$v.firstName.$model"
                type="text"
                class="c-text-input"
                :class="{
                  'c-text-input--error': (isSubmitted || $v.firstName.$dirty) && $v.firstName.$invalid
                }"
              >
              <FormErrorLine
                v-if="(isSubmitted || $v.firstName.$dirty) && $v.firstName.$invalid"
                class="u-mt--xs"
                :text="model.validationErrorText"
              />
            </div>
            <div class="u-flex u-flex-direction-column u-mb--s">
              <label class="c-label--m u-mb--xs">{{ model.teacherSection.lastNameLabel }}</label>
              <input
                v-model="$v.lastName.$model"
                type="text"
                class="c-text-input"
                :class="{
                  'c-text-input--error': (isSubmitted || $v.lastName.$dirty) && $v.lastName.$invalid
                }"
              >
              <FormErrorLine
                v-if="(isSubmitted || $v.lastName.$dirty) && $v.lastName.$invalid"
                class="u-mt--xs"
                :text="model.validationErrorText"
              />
            </div>
            <div class="u-flex u-flex-direction-column u-mb--s">
              <label class="c-label--m u-mb--xs">{{ model.teacherSection.specialtyLabel }}</label>
              <input
                v-model="$v.specialty.$model"
                type="text"
                class="c-text-input"
                :class="{
                  'c-text-input--error': (isSubmitted || $v.specialty.$dirty) && $v.specialty.$invalid
                }"
              >
              <FormErrorLine
                v-if="(isSubmitted || $v.specialty.$dirty) && $v.specialty.$invalid"
                class="u-mt--xs"
                :text="model.validationErrorText"
              />
            </div>
            <div class="u-flex u-flex-direction-column u-mb--s">
              <label class="c-label--m u-mb--xs">{{ model.teacherSection.phoneNumberLabel }}</label>
              <input
                v-model="$v.phoneNumber.$model"
                type="tel"
                class="c-text-input"
                :class="{
                  'c-text-input--error': (isSubmitted || $v.phoneNumber.$dirty) && $v.phoneNumber.$invalid
                }"
              >
              <FormErrorLine
                v-if="(isSubmitted || $v.phoneNumber.$dirty) && $v.phoneNumber.$invalid"
                class="u-mt--xs"
                :text="model.validationErrorText"
              />
            </div>
            <div class="u-flex u-flex-direction-column u-mb--s">
              <label class="c-label--m u-mb--xs">{{ model.teacherSection.emailLabel }}</label>
              <input
                :value="currentUserEmail"
                type="text"
                class="c-text-input"
                disabled
              >
              <span class="u-mt--xs u-font-size-small">
                {{ model.teacherSection.profileInfoText }}
                <a :href="model.teacherSection.profileInfoLink.url">{{ model.teacherSection.profileInfoLink.text }}</a>
              </span>
            </div>
          </div>
          <div class="u-mt--m">
            <h3 class="u-mb--m">
              {{ model.schoolSection.sectionHeading }}
            </h3>
            <div class="c-foodmovers__form-section">
              <div class="u-flex u-flex-direction-column u-mb--s">
                <label class="c-label--m u-mb--xs">{{ model.schoolSection.nameLabel }}</label>
                <autocomplete
                  v-if="suggestedSchools.length"
                  class="c-foodmovers-autocomplete"
                  base-class="c-text"
                  :search="searchSchool"
                  :get-result-value="getSuggestedSchoolName"
                  :default-value="schoolName || ''"
                  @submit="onSchoolSearchSubmit"
                />
                <input
                  v-model="$v.schoolName.$model"
                  :type="suggestedSchools.length ? 'hidden' : 'text'"
                  class="c-text-input"
                  :class="{
                    'c-text-input--error': (isSubmitted || $v.schoolName.$dirty) && $v.schoolName.$invalid
                  }"
                >
                <FormErrorLine
                  v-if="(isSubmitted || $v.schoolName.$dirty) && $v.schoolName.$invalid"
                  class="u-mt--xs"
                  :text="model.validationErrorText"
                />
              </div>
              <div class="u-flex u-flex-direction-column u-mb--s">
                <label class="c-label--m u-mb--xs">{{ model.schoolSection.addressLabel }}</label>
                <input
                  v-model="$v.schoolAddress.$model"
                  type="text"
                  class="c-text-input"
                  :class="{
                    'c-text-input--error': (isSubmitted || $v.schoolAddress.$dirty) && $v.schoolAddress.$invalid
                  }"
                >
                <FormErrorLine
                  v-if="(isSubmitted || $v.schoolAddress.$dirty) && $v.schoolAddress.$invalid"
                  class="u-mt--xs"
                  :text="model.validationErrorText"
                />
              </div>
              <div class="u-flex u-flex-direction-column u-mb--s">
                <label class="c-label--m u-mb--xs">{{ model.schoolSection.postalCodeLabel }}</label>
                <input
                  v-model="$v.schoolPostalCode.$model"
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="c-text-input c-foodmovers__form-postalcode"
                  :class="{
                    'c-text-input--error': (isSubmitted || $v.schoolPostalCode.$dirty) && $v.schoolPostalCode.$invalid
                  }"
                >
                <FormErrorLine
                  v-if="(isSubmitted || $v.schoolPostalCode.$dirty) && $v.schoolPostalCode.$invalid"
                  class="u-mt--xs"
                  :text="model.postalCodeValidationErrorText"
                />
              </div>
              <div class="u-flex u-flex-direction-column u-mb--m">
                <label class="c-label--m u-mb--xs">{{ model.schoolSection.cityLabel }}</label>
                <input
                  v-model="$v.schoolCity.$model"
                  type="text"
                  class="c-text-input"
                  :class="{
                    'c-text-input--error': (isSubmitted || $v.schoolCity.$dirty) && $v.schoolCity.$invalid
                  }"
                >
                <FormErrorLine
                  v-if="(isSubmitted || $v.schoolCity.$dirty) && $v.schoolCity.$invalid"
                  class="u-mt--xs"
                  :text="model.validationErrorText"
                />
              </div>
              <div class="u-flex u-flex-direction-column u-mb--m">
                <label class="c-label--m u-mb--xs">{{ model.schoolSection.communeLabel }}</label>
                <input
                  v-model="schoolCommune"
                  type="text"
                  class="c-text-input"
                >
              </div>
            </div>
            <template v-if="!profileExists">
              <h3 class="u-mb--s">
                {{ model.checkboxSection.sectionHeading }}
              </h3>
              <p class="u-m--0 u-mb--m u-font-size-large">
                {{ model.checkboxSection.sectionDescription }}
              </p>
              <div class="c-foodmovers__checkbox-container">
                <div class="u-mb--s">
                  <Checkbox
                    v-model="acceptTerms"
                    :required="true"
                  >
                    {{ model.acceptCheckbox.text }}
                    <a
                      :href="model.acceptCheckbox.link.url"
                      :target="model.acceptCheckbox.link.openInNewWindow ? '_blank' : null"
                      :rel="model.acceptCheckbox.link.openInNewWindow
                          ? 'noreferrer noopener' : null"
                      class="u-text-underline u-ml--xxs"
                    >
                      {{ model.acceptCheckbox.link.text }}
                    </a>
                  </Checkbox>
                  <FormErrorLine
                    v-if="isSubmitted && !acceptTerms"
                    class="u-mt--xs"
                    :text="model.validationErrorText"
                  />
                </div>
                <Checkbox
                  v-model="acceptNewsletterSubscription"
                  class="u-mb--l"
                  :required="true"
                >
                  {{ model.newsletterSubscriptionCheckbox.text }}
                  <a
                    :href="model.newsletterSubscriptionCheckbox.link.url"
                    :target="model.newsletterSubscriptionCheckbox.link.openInNewWindow
                        ? '_blank' : null"
                    :rel="model.newsletterSubscriptionCheckbox.link.openInNewWindow
                        ? 'noreferrer noopener' : null"
                    class="u-text-underline u-ml--xxs"
                  >
                    {{ model.newsletterSubscriptionCheckbox.link.text }}
                  </a>
                </Checkbox>
              </div>
            </template>
            <div class="u-flex u-flex-align-start u-flex-wrap">
              <div class="u-flex u-flex-direction-column u-flex-align-start">
                <button
                  class="c-button c-button--dark"
                  :class="{
                    'is-loading': isLoading
                  }"
                  :disabled="!acceptTerms"
                >
                  <span class="c-button__text">
                    {{ model.submitButtonText }}
                  </span>
                  <Loader
                    v-if="isLoading"
                    size="small"
                    class="c-button__loader"
                    color="medium-gray"
                  />
                </button>
                <FormErrorLine
                  v-if="submitError"
                  class="u-mt--xs"
                  :text="model.submitFormErrorText"
                />
              </div>
              <button
                v-if="teacher"
                class="c-button c-button--light u-ml--xs u-mr--s"
                @click="returnToDashboard"
              >
                {{ model.cancelButtonText }}
              </button>
              <a
                v-if="teacher && model.deleteProfileButtonText"
                class="u-flex-self-center u-mt--s u-mb--s"
                href="#"
                @click.prevent="showRemoveProfileDialog"
              >
                {{ model.deleteProfileButtonText }}
              </a>
            </div>
          </div>
        </form>
        <Picture
          v-if="model.image"
          class="c-foodmovers__form-picture u-flex-shrink-0"
          :class="{
            'u-ml--a': $mq == 'desktop',
            'u-mt--m': $mq != 'desktop'
          }"
          :src="model.image"
          :resizer="model.imageResizer.name"
          :alt="model.heading"
          :desktop="[400, 380]"
          :mobile="[320, 240]"
          :options="{ mode: 'max' }"
        />
      </div>
    </div>
    <Modal
      v-model="removeProfileIsShown"
      :is-title-centered="false"
      :width="540"
      :title="model.deleteProfilePopup.heading"
    >
      <DeleteProfileModal
        :model="model.deleteProfilePopup"
        :logout-url="logoutUrl"
        @cancel-deleting="hideRemoveProfileDialog"
      />
    </Modal>
  </div>
</template>

<script>
import {
  numeric, required, minLength, maxLength
} from 'vuelidate/lib/validators'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import Checkbox from '@/CVI/WebCore/components/CheckboxWithLabel.vue'
import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import api from '@/CVI/FoodMovers/lib/api'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import DeleteProfileModal from '@/CVI/FoodMovers/components/DeleteProfile.vue'

export default {
  components: {
    Checkbox,
    FormErrorLine,
    Loader,
    Modal,
    Picture,
    DeleteProfileModal,
    Autocomplete
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    teacher: {
      type: Object,
      default: () => {}
    },
    currentUserEmail: {
      type: String,
      required: true
    },
    suggestedSchools: {
      type: Array,
      default: () => []
    },
    logoutUrl: {
      type: String,
      required: true
    }
  },
  data() {
    const profileExists = this.teacher != null
    const {
      lastName, firstName, specialty, phoneNumber, school, classes = []
    } = this.teacher || {}

    const {
      name, address, postalCode, city, commune
    } = school || {}

    return {
      lastName,
      firstName,
      specialty,
      phoneNumber,
      classes,
      schoolCity: city,
      schoolCommune: commune,
      schoolPostalCode: postalCode,
      schoolAddress: address,
      schoolName: name,
      acceptTerms: profileExists,
      acceptNewsletterSubscription: false,
      isLoading: false,
      isSubmitted: false,
      submitError: false,
      profileExists,
      removeProfileIsShown: false
    }
  },

  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    specialty: {
      required
    },
    phoneNumber: {
      required
    },
    schoolName: {
      required
    },
    schoolAddress: {
      required
    },
    schoolPostalCode: {
      required,
      numeric,
      maxLength: maxLength(4),
      minLength: minLength(4)
    },
    schoolCity: {
      required
    }
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()
      this.isSubmitted = true
      this.submitError = false
      if (this.$v.$invalid && !this.acceptTerms) {
        return
      }
      this.isLoading = true
      const {
        firstName, lastName, specialty, phoneNumber,
        schoolPostalCode, schoolName, schoolAddress,
        schoolCity, acceptTerms, acceptNewsletterSubscription,
        schoolCommune, classes
      } = this

      const teacher = {
        firstName,
        lastName,
        specialty,
        phoneNumber,
        schoolName,
        schoolAddress,
        schoolPostalCode,
        schoolCity,
        schoolCommune,
        acceptTerms,
        acceptNewsletterSubscription
      }

      try {
        await api.foodmovers.post(teacher)
        const dashboardTeacherModel = {
          firstName,
          lastName,
          specialty,
          phoneNumber,
          classes,
          school: {
            name: schoolName,
            address: schoolAddress,
            postalCode: schoolPostalCode,
            city: schoolCity,
            commune: schoolCommune
          }
        }
        this.$emit('form-submitted', dashboardTeacherModel)
      } catch (e) {
        this.submitError = true
      }
      this.isLoading = false
    },
    returnToDashboard() {
      this.$emit('form-cancel')
    },
    searchSchool(input) {
      if (input.length < 1) { return [] }
      this.schoolName = input
      return this.suggestedSchools.filter(school => school.name.toLowerCase()
        .startsWith(input.toLowerCase()))
    },
    getSuggestedSchoolName(result) {
      const { name } = result
      return name
    },
    onSchoolSearchSubmit({
      name, address, postalCode, commune, city
    }) {
      this.schoolName = name
      this.schoolAddress = address
      this.schoolPostalCode = postalCode
      this.schoolCommune = commune
      this.schoolCity = city
    },
    showRemoveProfileDialog() {
      this.removeProfileIsShown = true
    },
    hideRemoveProfileDialog() {
      this.removeProfileIsShown = false
    }
  }
}
</script>
