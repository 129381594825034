<template>
  <div class="o-content-box">
    <div class="u-width-720 u-ml--a u-mr--a">
      <h1 class="u-font-size-h2 u-font-alternative">
        {{ model.heading }}
      </h1>
      <p class="u-font-size-xlarge u-mb--m">
        {{ model.loginDescription }}
      </p>
      <div class="u-flex u-flex-align-center">
        <a
          v-if="!cantEdit"
          class="c-button c-button--dark u-mr--xs"
          href="#login"
        >
          {{ model.registrationButtonText }}
        </a>
        <a
          class="c-button c-button--light"
          href="#login"
        >
          {{ model.loginButtonText }}
        </a>
      </div>
      <p
        v-if="cantEdit"
        class="u-font-size-medium"
      >
        {{ registrationClosedMessageText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    cantEdit: {
      type: Boolean,
      required: true
    },
    amountOfStudentsExceededMessageText: {
      type: String,
      default: ''
    },
    registrationClosedMessageText: {
      type: String,
      default: ''
    }
  }
}
</script>
