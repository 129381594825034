var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-content-box"},[_c('div',{staticClass:"u-width-720 u-ml--a u-mr--a"},[_c('h1',{staticClass:"u-font-size-h2 u-font-alternative"},[_vm._v(" "+_vm._s(_vm.model.heading)+" ")]),_c('p',{staticClass:"u-m--0 u-mb--m u-font-size-xlarge"},[_vm._v(" "+_vm._s(_vm.model.description)+" ")]),_c('h3',{staticClass:"u-mb--m"},[_vm._v(" "+_vm._s(_vm.model.teacherSection.sectionHeading)+" ")]),_c('div',{staticClass:"u-flex u-flex-direction-column@mobile"},[_c('form',{staticClass:"c-foodmovers__form",attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();return _vm.submitForm($event)}}},[_c('div',{staticClass:"c-foodmovers__form-section"},[_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.teacherSection.firstNameLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.firstName.$model),expression:"$v.firstName.$model"}],staticClass:"c-text-input",class:{
                'c-text-input--error': (_vm.isSubmitted || _vm.$v.firstName.$dirty) && _vm.$v.firstName.$invalid
              },attrs:{"type":"text"},domProps:{"value":(_vm.$v.firstName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.firstName, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.firstName.$dirty) && _vm.$v.firstName.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.teacherSection.lastNameLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.lastName.$model),expression:"$v.lastName.$model"}],staticClass:"c-text-input",class:{
                'c-text-input--error': (_vm.isSubmitted || _vm.$v.lastName.$dirty) && _vm.$v.lastName.$invalid
              },attrs:{"type":"text"},domProps:{"value":(_vm.$v.lastName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.lastName, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.lastName.$dirty) && _vm.$v.lastName.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.teacherSection.specialtyLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.specialty.$model),expression:"$v.specialty.$model"}],staticClass:"c-text-input",class:{
                'c-text-input--error': (_vm.isSubmitted || _vm.$v.specialty.$dirty) && _vm.$v.specialty.$invalid
              },attrs:{"type":"text"},domProps:{"value":(_vm.$v.specialty.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.specialty, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.specialty.$dirty) && _vm.$v.specialty.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.teacherSection.phoneNumberLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.phoneNumber.$model),expression:"$v.phoneNumber.$model"}],staticClass:"c-text-input",class:{
                'c-text-input--error': (_vm.isSubmitted || _vm.$v.phoneNumber.$dirty) && _vm.$v.phoneNumber.$invalid
              },attrs:{"type":"tel"},domProps:{"value":(_vm.$v.phoneNumber.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.phoneNumber, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.phoneNumber.$dirty) && _vm.$v.phoneNumber.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.teacherSection.emailLabel))]),_c('input',{staticClass:"c-text-input",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.currentUserEmail}}),_c('span',{staticClass:"u-mt--xs u-font-size-small"},[_vm._v(" "+_vm._s(_vm.model.teacherSection.profileInfoText)+" "),_c('a',{attrs:{"href":_vm.model.teacherSection.profileInfoLink.url}},[_vm._v(_vm._s(_vm.model.teacherSection.profileInfoLink.text))])])])]),_c('div',{staticClass:"u-mt--m"},[_c('h3',{staticClass:"u-mb--m"},[_vm._v(" "+_vm._s(_vm.model.schoolSection.sectionHeading)+" ")]),_c('div',{staticClass:"c-foodmovers__form-section"},[_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.schoolSection.nameLabel))]),(_vm.suggestedSchools.length)?_c('autocomplete',{staticClass:"c-foodmovers-autocomplete",attrs:{"base-class":"c-text","search":_vm.searchSchool,"get-result-value":_vm.getSuggestedSchoolName,"default-value":_vm.schoolName || ''},on:{"submit":_vm.onSchoolSearchSubmit}}):_vm._e(),((_vm.suggestedSchools.length ? 'hidden' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.schoolName.$model),expression:"$v.schoolName.$model"}],staticClass:"c-text-input",class:{
                  'c-text-input--error': (_vm.isSubmitted || _vm.$v.schoolName.$dirty) && _vm.$v.schoolName.$invalid
                },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v.schoolName.$model)?_vm._i(_vm.$v.schoolName.$model,null)>-1:(_vm.$v.schoolName.$model)},on:{"change":function($event){var $$a=_vm.$v.schoolName.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.schoolName, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.schoolName, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.schoolName, "$model", $$c)}}}}):((_vm.suggestedSchools.length ? 'hidden' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.schoolName.$model),expression:"$v.schoolName.$model"}],staticClass:"c-text-input",class:{
                  'c-text-input--error': (_vm.isSubmitted || _vm.$v.schoolName.$dirty) && _vm.$v.schoolName.$invalid
                },attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.$v.schoolName.$model,null)},on:{"change":function($event){return _vm.$set(_vm.$v.schoolName, "$model", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.schoolName.$model),expression:"$v.schoolName.$model"}],staticClass:"c-text-input",class:{
                  'c-text-input--error': (_vm.isSubmitted || _vm.$v.schoolName.$dirty) && _vm.$v.schoolName.$invalid
                },attrs:{"type":_vm.suggestedSchools.length ? 'hidden' : 'text'},domProps:{"value":(_vm.$v.schoolName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.schoolName, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.schoolName.$dirty) && _vm.$v.schoolName.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.schoolSection.addressLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.schoolAddress.$model),expression:"$v.schoolAddress.$model"}],staticClass:"c-text-input",class:{
                  'c-text-input--error': (_vm.isSubmitted || _vm.$v.schoolAddress.$dirty) && _vm.$v.schoolAddress.$invalid
                },attrs:{"type":"text"},domProps:{"value":(_vm.$v.schoolAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.schoolAddress, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.schoolAddress.$dirty) && _vm.$v.schoolAddress.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--s"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.schoolSection.postalCodeLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.schoolPostalCode.$model),expression:"$v.schoolPostalCode.$model"}],staticClass:"c-text-input c-foodmovers__form-postalcode",class:{
                  'c-text-input--error': (_vm.isSubmitted || _vm.$v.schoolPostalCode.$dirty) && _vm.$v.schoolPostalCode.$invalid
                },attrs:{"type":"text","inputmode":"numeric","pattern":"[0-9]*"},domProps:{"value":(_vm.$v.schoolPostalCode.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.schoolPostalCode, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.schoolPostalCode.$dirty) && _vm.$v.schoolPostalCode.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.postalCodeValidationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--m"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.schoolSection.cityLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.schoolCity.$model),expression:"$v.schoolCity.$model"}],staticClass:"c-text-input",class:{
                  'c-text-input--error': (_vm.isSubmitted || _vm.$v.schoolCity.$dirty) && _vm.$v.schoolCity.$invalid
                },attrs:{"type":"text"},domProps:{"value":(_vm.$v.schoolCity.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.schoolCity, "$model", $event.target.value)}}}),((_vm.isSubmitted || _vm.$v.schoolCity.$dirty) && _vm.$v.schoolCity.$invalid)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('div',{staticClass:"u-flex u-flex-direction-column u-mb--m"},[_c('label',{staticClass:"c-label--m u-mb--xs"},[_vm._v(_vm._s(_vm.model.schoolSection.communeLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolCommune),expression:"schoolCommune"}],staticClass:"c-text-input",attrs:{"type":"text"},domProps:{"value":(_vm.schoolCommune)},on:{"input":function($event){if($event.target.composing){ return; }_vm.schoolCommune=$event.target.value}}})])]),(!_vm.profileExists)?[_c('h3',{staticClass:"u-mb--s"},[_vm._v(" "+_vm._s(_vm.model.checkboxSection.sectionHeading)+" ")]),_c('p',{staticClass:"u-m--0 u-mb--m u-font-size-large"},[_vm._v(" "+_vm._s(_vm.model.checkboxSection.sectionDescription)+" ")]),_c('div',{staticClass:"c-foodmovers__checkbox-container"},[_c('div',{staticClass:"u-mb--s"},[_c('Checkbox',{attrs:{"required":true},model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.model.acceptCheckbox.text)+" "),_c('a',{staticClass:"u-text-underline u-ml--xxs",attrs:{"href":_vm.model.acceptCheckbox.link.url,"target":_vm.model.acceptCheckbox.link.openInNewWindow ? '_blank' : null,"rel":_vm.model.acceptCheckbox.link.openInNewWindow
                        ? 'noreferrer noopener' : null}},[_vm._v(" "+_vm._s(_vm.model.acceptCheckbox.link.text)+" ")])]),(_vm.isSubmitted && !_vm.acceptTerms)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.validationErrorText}}):_vm._e()],1),_c('Checkbox',{staticClass:"u-mb--l",attrs:{"required":true},model:{value:(_vm.acceptNewsletterSubscription),callback:function ($$v) {_vm.acceptNewsletterSubscription=$$v},expression:"acceptNewsletterSubscription"}},[_vm._v(" "+_vm._s(_vm.model.newsletterSubscriptionCheckbox.text)+" "),_c('a',{staticClass:"u-text-underline u-ml--xxs",attrs:{"href":_vm.model.newsletterSubscriptionCheckbox.link.url,"target":_vm.model.newsletterSubscriptionCheckbox.link.openInNewWindow
                      ? '_blank' : null,"rel":_vm.model.newsletterSubscriptionCheckbox.link.openInNewWindow
                      ? 'noreferrer noopener' : null}},[_vm._v(" "+_vm._s(_vm.model.newsletterSubscriptionCheckbox.link.text)+" ")])])],1)]:_vm._e(),_c('div',{staticClass:"u-flex u-flex-align-start u-flex-wrap"},[_c('div',{staticClass:"u-flex u-flex-direction-column u-flex-align-start"},[_c('button',{staticClass:"c-button c-button--dark",class:{
                  'is-loading': _vm.isLoading
                },attrs:{"disabled":!_vm.acceptTerms}},[_c('span',{staticClass:"c-button__text"},[_vm._v(" "+_vm._s(_vm.model.submitButtonText)+" ")]),(_vm.isLoading)?_c('Loader',{staticClass:"c-button__loader",attrs:{"size":"small","color":"medium-gray"}}):_vm._e()],1),(_vm.submitError)?_c('FormErrorLine',{staticClass:"u-mt--xs",attrs:{"text":_vm.model.submitFormErrorText}}):_vm._e()],1),(_vm.teacher)?_c('button',{staticClass:"c-button c-button--light u-ml--xs u-mr--s",on:{"click":_vm.returnToDashboard}},[_vm._v(" "+_vm._s(_vm.model.cancelButtonText)+" ")]):_vm._e(),(_vm.teacher && _vm.model.deleteProfileButtonText)?_c('a',{staticClass:"u-flex-self-center u-mt--s u-mb--s",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showRemoveProfileDialog($event)}}},[_vm._v(" "+_vm._s(_vm.model.deleteProfileButtonText)+" ")]):_vm._e()])],2)]),(_vm.model.image)?_c('Picture',{staticClass:"c-foodmovers__form-picture u-flex-shrink-0",class:{
          'u-ml--a': _vm.$mq == 'desktop',
          'u-mt--m': _vm.$mq != 'desktop'
        },attrs:{"src":_vm.model.image,"resizer":_vm.model.imageResizer.name,"alt":_vm.model.heading,"desktop":[400, 380],"mobile":[320, 240],"options":{ mode: 'max' }}}):_vm._e()],1)]),_c('Modal',{attrs:{"is-title-centered":false,"width":540,"title":_vm.model.deleteProfilePopup.heading},model:{value:(_vm.removeProfileIsShown),callback:function ($$v) {_vm.removeProfileIsShown=$$v},expression:"removeProfileIsShown"}},[_c('DeleteProfileModal',{attrs:{"model":_vm.model.deleteProfilePopup,"logout-url":_vm.logoutUrl},on:{"cancel-deleting":_vm.hideRemoveProfileDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }