var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-content-box"},[_c('div',{staticClass:"u-flex u-width-1280 u-flex-direction-column@mobile u-ml--a u-mr--a"},[_c('div',{staticClass:"u-flex-1"},[_c('h1',{staticClass:"u-font-size-h2 u-font-alternative"},[_vm._v(" "+_vm._s(_vm.model.heading)+" ")]),_c('p',{staticClass:"u-font-size-xlarge u-mb--m"},[_vm._v(" "+_vm._s(_vm.model.description)+" ")]),_vm._l((_vm.teacherModel.classes),function(teacherClass){return _c('div',{key:teacherClass.id,staticClass:"c-foodmovers__table u-flex u-flex-direction-column@mobile u-mb--s"},[_c('div',{staticClass:"u-flex u-flex-direction-column"},[_c('span',{staticClass:"c-label--m u-text-dark-gray"},[_vm._v(_vm._s(_vm.model.classSection.classNameLabel))]),_c('span',{staticClass:"u-font-bold u-font-size-h4 u-m--0"},[_vm._v(_vm._s(teacherClass.name))])]),_c('div',{staticClass:"u-flex",class:{
            'u-mt--m': _vm.$mq != 'desktop',
            'u-ml--a': _vm.$mq == 'desktop'
          }},[_c('div',{staticClass:"u-flex u-flex-direction-column u-mr--m"},[_c('span',{staticClass:"c-label--m u-text-dark-gray"},[_vm._v(_vm._s(_vm.model.classSection.week1PointsLabel))]),_c('span',{staticClass:"u-font-size-h4 u-m--0"},[_vm._v(_vm._s(teacherClass.week1Points))])]),_c('div',{staticClass:"u-flex u-flex-direction-column u-mr--m"},[_c('span',{staticClass:"c-label--m u-text-dark-gray"},[_vm._v(_vm._s(_vm.model.classSection.week2PointsLabel))]),_c('span',{staticClass:"u-font-size-h4 u-m--0"},[_vm._v(_vm._s(teacherClass.week2Points))])]),_c('div',{staticClass:"u-flex u-flex-direction-column u-mr--m"},[_c('span',{staticClass:"c-label--m u-text-dark-gray"},[_vm._v(_vm._s(_vm.model.classSection.week2PointsLabel))]),_c('span',{staticClass:"u-font-size-h4 u-m--0"},[_vm._v(_vm._s(teacherClass.week3Points))])])]),_c('div',{staticClass:"u-flex u-flex-align-center",class:{
            'u-mt--m u-mb--s': _vm.$mq != 'desktop'
          }},[_c('div',{staticClass:"u-flex u-flex-direction-column"},[_c('span',{staticClass:"c-label--m u-text-dark-gray"},[_vm._v(_vm._s(_vm.model.classSection.totalPointsLabel))]),_c('span',{staticClass:"u-font-size-h4 u-m--0 u-font-bold"},[_vm._v(_vm._s(teacherClass.totalPoints))])]),_c('div',{staticClass:"u-flex u-flex-align-center",class:{
              'u-ml--l': _vm.$mq == 'desktop',
              'u-ml--a': _vm.$mq != 'desktop'
            }},[_c('button',{staticClass:"c-button c-button--dark c-button--small",on:{"click":function($event){return _vm.editClass(teacherClass)}}},[_vm._v(" "+_vm._s(_vm.model.classSection.editClassButtonText)+" ")]),(!_vm.isDisabled)?_c('button',{staticClass:"c-button--icon-large",class:{
                'u-ml--m': _vm.$mq == 'desktop',
                'u-ml--s': _vm.$mq != 'desktop'
              },on:{"click":function($event){return _vm.showDeleteConfirmation(teacherClass)}}},[_c('Icon',{attrs:{"icon":_vm.iconTrash,"size":24,"fill":"black"}})],1):_vm._e()])])])}),_c('button',{staticClass:"c-foodmovers__add-class u-width-100 u-flex u-flex-justify-center u-flex-align-center u-mb--l",class:{'disabled': _vm.isDisabled},on:{"click":_vm.addClass}},[(!_vm.isDisabled)?_c('Icon',{attrs:{"icon":_vm.iconAdd,"size":16,"fill":"black"}}):_vm._e(),_c('span',{staticClass:"c-label--m u-ml--xs"},[(_vm.isDisabled)?[_vm._v(" "+_vm._s(_vm.amountOfStudentsExceededMessageText)+" ")]:[_vm._v(" "+_vm._s(_vm.model.addClassText)+" ")]],2)],1),_c('h3',{staticClass:"u-font-alternative u-font-size-h4"},[_vm._v(" "+_vm._s(_vm.model.reminderHeading)+" ")]),_c('div',{staticClass:"u-font-size-xlarge",domProps:{"innerHTML":_vm._s(_vm.model.reminderDescription)}})],2),_c('div',{class:{
        'u-ml--l': _vm.$mq == 'desktop'
      }},[_c('div',{staticClass:"c-label--m u-text-dark-gray u-mb--xs"},[_vm._v(" "+_vm._s(_vm.greetings)+" ")]),_c('div',{staticClass:"u-font-size-large"},[_c('div',[_vm._v(_vm._s(_vm.currentUserEmail))]),_c('div',[_vm._v(_vm._s(_vm.teacherModel.phoneNumber))]),_c('div',[_vm._v(_vm._s(_vm.teacherModel.school.name))]),_c('div',[_vm._v(_vm._s(_vm.teacherModel.school.address))]),_c('div',[_vm._v(_vm._s(_vm.teacherModel.school.postalCode)+" "+_vm._s(_vm.teacherModel.school.city))])]),_c('div',{staticClass:"u-mt--s u-mb--m"},[_c('button',{staticClass:"c-button c-button--small c-button--light",on:{"click":function($event){$event.preventDefault();return _vm.editForm($event)}}},[_vm._v(" "+_vm._s(_vm.model.sidebarEditProfileLinkText)+" ")])]),_c('a',{attrs:{"href":_vm.model.sidebarLogoutUrl}},[_vm._v(" "+_vm._s(_vm.model.sidebarLogoutText))])])]),_c('Modal',{attrs:{"is-title-centered":false,"width":540,"title":_vm.model.classPopupViewModel.heading},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ClassModal',{attrs:{"model":_vm.model.classPopupViewModel,"cant-edit":_vm.isDisabled,"teacher-class":_vm.activeClass},on:{"class-saved":_vm.classSaved,"class-deleted":_vm.deleteClass,"class-cancel":_vm.closeModal,"amount-updated":_vm.amountUpdated}})],1),_c('Modal',{attrs:{"is-title-centered":false,"width":540,"title":_vm.model.deleteClassPopup.heading},model:{value:(_vm.deleteConfirmationShown),callback:function ($$v) {_vm.deleteConfirmationShown=$$v},expression:"deleteConfirmationShown"}},[_c('DeleteClassModal',{attrs:{"model":_vm.model.deleteClassPopup,"delete-id":_vm.classToDelete.id},on:{"class-deleted":_vm.deleteClass,"cancel-deleting":_vm.hideDeleteConfirmation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }