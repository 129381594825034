<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <LoginAndRegister
        v-if="!model.currentUserEmail"
        :model="model.dashboard"
        :cant-edit="model.cantEdit"
        :amount-of-students-exceeded-message-text="model.amountOfStudentsExceededMessageText"
        :registration-closed-message-text="model.registrationClosedMessageText"
      />

      <RegistrationForm
        v-if="(!teacher && model.currentUserEmail) || editForm"
        :model="model.registrationForm"
        :teacher="teacher"
        :current-user-email="model.currentUserEmail"
        :suggested-schools="model.suggestedSchools"
        :logout-url="model.dashboard.sidebarLogoutUrl"
        @form-submitted="teacherSaved"
        @form-cancel="editForm = false"
      />
      <Dashboard
        v-if="teacher && teacher.school && !editForm"
        :model="model.dashboard"
        :cant-edit="model.cantEdit"
        :amount-of-students-exceeded-message-text="model.amountOfStudentsExceededMessageText"
        :teacher="teacher"
        :current-user-email="model.currentUserEmail"
        @edit-form="editForm = true"
      />
    </transition>
  </div>
</template>

<script>
import RegistrationForm from '@/CVI/FoodMovers/components/RegistrationForm.vue'
import Dashboard from '@/CVI/FoodMovers/components/Dashboard.vue'
import LoginAndRegister from '@/CVI/FoodMovers/components/LoginAndRegister.vue'

export default {
  components: {
    Dashboard,
    LoginAndRegister,
    RegistrationForm
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      teacher: this.model.teacher,
      editForm: false
    }
  },
  watch: {
    editForm() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
    }
  },
  methods: {
    teacherSaved(teacher) {
      this.editForm = false
      this.teacher = teacher
    }
  }
}
</script>
