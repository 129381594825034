import axios from 'axios'

const language = document.documentElement.lang

export default {
  foodmovers: {
    baseUrl: `/cvi/foodmovers/api/${language}`,
    post(teacher) {
      return axios.post(`${this.baseUrl}/profile/`, teacher)
    },
    updateClass(teacherClass) {
      return axios.post(`${this.baseUrl}/classes/`, teacherClass)
    },
    deleteClass(id) {
      return axios.delete(`${this.baseUrl}/classes/${id}`)
    },
    deleteProfile() {
      return axios.delete(`${this.baseUrl}/profile`)
    }
  }
}
