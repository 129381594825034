<template>
  <form
    ref="form"
    novalidate
    @submit.stop="saveClass"
  >
    <p
      class="u-font-size-large u-m--0 "
      :class="{
        'u-mb--m': $mq == 'desktop',
        'u-mb--s': $mq != 'desktop'
      }"
    >
      {{ model.description }}
    </p>
    <div class="u-flex">
      <div class="c-foodmovers__class-form-column u-mt--s">
        <div class="u-flex u-flex-direction-column u-mb--s">
          <label class="c-label--m u-mb--xs">
            {{ model.classNameLabel }} *
          </label>
          <input
            v-model="$v.name.$model"
            :disabled="isDisabled"
            type="text"
            class="c-text-input"
            :class="{
              'c-text-input--error': (isSubmitted || $v.name.$dirty) && $v.name.$invalid
            }"
          >
          <FormErrorLine
            v-if="(isSubmitted || $v.name.$dirty) && $v.name.$invalid"
            class="u-mt--xs"
            :text="model.validationErrorText"
          />
        </div>
        <div class="u-flex u-flex-direction-column u-mb--s">
          <label class="c-label--m u-mb--xs">
            {{ model.gradeLabel }} *
          </label>
          <Dropdown
            v-model="$v.gradeLevel.$model"
            :disabled="isDisabled"
            :class="{
              'c-text-input--error': (isSubmitted || $v.gradeLevel.$dirty) && $v.gradeLevel.$invalid
            }"
            class="c-foodmovers__class-dropdown"
            :items="gradeSelector"
            :small-arrow="true"
            container=".c-foodmovers__class-form-column"
          />
          <FormErrorLine
            v-if="(isSubmitted || $v.gradeLevel.$dirty) && $v.gradeLevel.$invalid"
            class="u-mt--xs"
            :text="model.validationErrorText"
          />
        </div>
        <div class="u-flex u-flex-direction-column u-mb--s">
          <label class="c-label--m u-mb--xs">
            {{ model.numberOfStudentsLabel }} *
          </label>
          <input
            v-model.number="$v.numberOfStudents.$model"
            :disabled="isDisabled"
            type="number"
            class="c-text-input u-width-60"
            pattern="[0-9]*"
            inputmode="numeric"
            :class="{
              'c-text-input--error': (isSubmitted || $v.numberOfStudents.$dirty) && $v.numberOfStudents.$invalid
            }"
          >
          <FormErrorLine
            v-if="(isSubmitted || $v.numberOfStudents.$dirty) && $v.numberOfStudents.$invalid"
            class="u-mt--xs"
            :text="model.numberOfStudentsValidationMessage"
          />
        </div>
      </div>
      <div
        class="c-foodmovers__class-form-column u-mt--s"
        :class="{
          'u-ml--xl': $mq == 'desktop',
          'u-ml--m': $mq != 'desktop'
        }"
      >
        <div class="u-flex u-flex-direction-column u-mb--s">
          <label class="c-label--m u-mb--xs">
            {{ model.week1PointsLabel }}
          </label>
          <input
            v-model.number="$v.week1Points.$model"
            type="number"
            :placeholder="model.maxNumberOfPointsText"
            class="c-text-input u-width-80"
            pattern="[0-9]*"
            inputmode="numeric"
          >
          <FormErrorLine
            v-if="$v.week1Points.$invalid"
            class="u-mt--xs"
            :text="model.validationErrorText"
          />
        </div>
        <div class="u-flex u-flex-direction-column u-mb--s">
          <label class="c-label--m u-mb--xs">
            {{ model.week2PointsLabel }}
          </label>
          <input
            v-model.number="$v.week2Points.$model"
            type="number"
            :placeholder="model.maxNumberOfPointsText"
            class="c-text-input u-width-80"
            pattern="[0-9]*"
            inputmode="numeric"
          >
          <FormErrorLine
            v-if="$v.week2Points.$invalid"
            class="u-mt--xs"
            :text="model.validationErrorText"
          />
        </div>
        <div class="u-flex u-flex-direction-column">
          <label class="c-label--m u-mb--xs">
            {{ model.week3PointsLabel }}
          </label>
          <input
            v-model.number="$v.week3Points.$model"
            type="number"
            :placeholder="model.maxNumberOfPointsText"
            class="c-text-input u-width-80"
            pattern="[0-9]*"
            inputmode="numeric"
          >
          <FormErrorLine
            v-if="$v.week3Points.$invalid"
            class="u-mt--xs"
            :text="model.validationErrorText"
          />
        </div>
      </div>
    </div>
    <FormErrorLine
      v-if="submitError"
      class="u-mt--xs u-mb--xs"
      :text="model.submitPopupErrorText"
    />
    <div class="u-flex u-mt--l">
      <button
        v-if="id && !isDisabled"
        class="c-button c-button--ghost u-mr--a"
        type="button"
        :class="{
          'is-loading': isRemoving
        }"
        @click="deleteClass"
      >
        <span class="c-button__text">
          {{ model.deleteButtonText }}
        </span>
        <Loader
          v-if="isRemoving"
          size="small"
          class="c-button__loader"
          color="medium-gray"
        />
      </button>

      <button
        class="c-button c-button--light-gray u-ml--a u-mr--xs"
        type="button"
        @click="closeModal"
      >
        {{ model.cancelButtonText }}
      </button>
      <button
        class="c-button c-button--dark"
        :class="{
          'is-loading': savingClass
        }"
      >
        <span class="c-button__text">

          {{ model.saveButtonText }}
        </span>
        <Loader
          v-if="savingClass"
          size="small"
          class="c-button__loader"
          color="medium-gray"
        />
      </button>
    </div>
  </form>
</template>

<script>
import {
  numeric, required, maxValue, minValue, integer
} from 'vuelidate/lib/validators'
import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import api from '@/CVI/FoodMovers/lib/api'
import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'

export default {
  components: {
    Dropdown,
    Loader,
    FormErrorLine
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    teacherClass: {
      type: Object,
      required: true
    },
    cantEdit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    const {
      id, name, gradeLevel, numberOfStudents, week1Points, week2Points, week3Points
    } = this.teacherClass
    const { gradeMaxValue } = this.model

    const gradeSelector = Array.from(Array(gradeMaxValue + 1).keys()).map(value => ({
      id: value,
      name: value
    }))

    return {
      savingClass: false,
      id,
      name,
      gradeLevel,
      gradeSelector,
      numberOfStudents,
      week1Points,
      week2Points,
      week3Points,
      isRemoving: false,
      submitError: false,
      isSubmitted: false,
      isDisabled: this.cantEdit
    }
  },
  validations() {
    const { maxNumberOfPoints, numberOfStudentsMaxValue, gradeMaxValue } = this.model
    return {
      name: {
        required
      },
      gradeLevel: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(gradeMaxValue)
      },
      numberOfStudents: {
        required,
        numeric,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(numberOfStudentsMaxValue)
      },
      week1Points: {
        numeric,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(maxNumberOfPoints)
      },
      week2Points: {
        numeric,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(maxNumberOfPoints)
      },
      week3Points: {
        numeric,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(maxNumberOfPoints)
      }
    }
  },
  methods: {
    async saveClass(e) {
      e.preventDefault()
      this.isSubmitted = true
      this.submitError = false
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          const firstErrorElement = this.$refs.form.querySelector('.c-text-input--error')
          if (firstErrorElement && firstErrorElement.previousSibling) {
            firstErrorElement.previousSibling.scrollIntoViewIfNeeded()
          }
        })
        return
      }
      this.savingClass = true
      const {
        id, name, gradeLevel, numberOfStudents, week1Points, week2Points, week3Points
      } = this

      try {
        const { data } = await api.foodmovers.updateClass({
          id, name, gradeLevel, numberOfStudents, week1Points, week2Points, week3Points
        })
        this.$emit('class-saved', data.teacherClass)
        this.isDisabled = data.cantEdit
        this.$emit('amount-updated', data.cantEdit)
      } catch (e) {
        console.log(e)
        this.submitError = true
      }
      this.isSubmitted = false
      this.savingClass = false
    },
    async deleteClass(e) {
      e.preventDefault()
      this.isRemoving = true
      const { id } = this
      const { data } = await api.foodmovers.deleteClass(id)
      this.isRemoving = false
      this.$emit('class-deleted', id)
      this.isDisabled = data.cantEdit
      this.$emit('amount-updated', data.cantEdit)
    },
    closeModal() {
      this.$emit('class-cancel')
    }
  }
}
</script>
